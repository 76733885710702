import React from 'react'

const BannerWToP = () => {
  return (
    <div className="bg-banner-w-to-p bg-12.5 h-[63px] opacity-30">

    </div>
  )
}

export default BannerWToP
