const opinions = [
  {
    text: "Gracias Paula por cuidar de Travis tan bien. Volveremos. ❤️🐶",
    author: "garridocanolaura",
  },
  {
    text: "Max se lo pasó genial cuando os lo deje las pasadas vacaciones.            Gracias",
    author: "davidpascualsoriano",
  },
  {
    text: "¡Rayo y Mía están felices! Muchas gracias ❤️ contigo los dejo            tranquila 😊",
    author: "carmen.c.c.r",
  },
  {
    text: "Que bien lo pasan y que felices están 😍👏",
    author: "annablascosoria",
  },
];
export default opinions;
