import galeria1 from "../../img/galeria1.jpg";
import galeria3 from "../../img/galeria3.jpg";
import galeria4 from "../../img/galeria4.jpg";
import galeria5 from "../../img/galeria5.jpg";
import galeria7 from "../../img/galeria7.jpg";
import galeria8 from "../../img/galeria8.jpg";
import galeria9 from "../../img/galeria9.jpg";
import galeria10 from "../../img/galeria10.jpg";
import galeria11 from "../../img/galeria11.jpg";
import galeria12 from "../../img/galeria12.jpg";
import galeria13 from "../../img/galeria13.jpg";
import galeria14 from "../../img/galeria14.jpg";

const gallery = [
  {
    src: galeria1,
  },
  {
    src: galeria3,
  },
  {
    src: galeria4,
  },
  {
    src: galeria5,
  },
  {
    src: galeria7,
  },
  {
    src: galeria8,
  },
  {
    src: galeria9,
  },
  {
    src: galeria10,
  },
  {
    src: galeria11,
  },
  {
    src: galeria12,
  },
  {
    src: galeria13,
  },
  {
    src: galeria14,
  },
];
export default gallery;
