import React from 'react'

const BannerPToW = () => {
  return (
    <div className="bg-banner-p-to-w bg-12.5 h-[63px] opacity-30 ">

    </div>
  )
}

export default BannerPToW
